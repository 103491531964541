<template>
  <div :class="['breadcrumb', goBack ? 'plan' : '']" :style="backgroundStyle">
    <div class="breadcrumb-cover"></div>
    <div class="container-fluid">
      <div class="row breadcrumb-content">
        <div :class="[hideOnMobile || goBack ? 'col-12' : 'col-8', goBack ? 'col-lg-6' : 'col-lg-6', goBack ? 'd-flex' : '']">
          <button v-if="goBack" @click="goToBack" class="back-arrow mr-4"><span class="material-icons">navigate_before</span></button>
          <h1 :class="['events-title', goBack ? 'events-title-sm' : '']" v-if="title === 'Events'">Events in der <br>Eckernförder Bucht</h1>
          <h1 :class="['title', goBack ? 'title-sm' : '']" v-else>{{ title }}</h1>
          <h6 class="subtitle" v-if="subtitle">{{ subtitle }}</h6>
        </div>
        <div :class="[hideOnMobile ? 'd-none d-lg-block' : 'col-4', 'col-lg-6']"><slot></slot></div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'Breadcrumb',
    props: {
      title: {
        type: String,
        required: true
      },
      subtitle: String,
      bgImage: String,
      goBack: Boolean,
      marginMdLeft: Boolean,
      hideOnMobile: {
        type: Boolean,
        default: false
      }
    },
    computed: {
      backgroundStyle () {
        return {
          backgroundImage: `url(${this.bgImage})`
        }
      }
    },
    methods: {
      goToBack() {
        this.$router.go(-1);
      }
    },
  }
</script>
